import { createStore, combineReducers, applyMiddleware, compose } from 'redux'

import storeReducer from '../redux/actions/store';
import orderReducer from '../redux/actions/order';
import printerReducer from '../redux/actions/printer';

const rootReducer = combineReducers({
  store: storeReducer,
  order: orderReducer,
  printer: printerReducer
})

const store = createStore(rootReducer)
export default store
