const INIT_STORE = '@@INIT_STORE';
const SET_STORE_CD = '@@SET_STORE_CD';
const SET_STORE = '@@SET_STORE';
const SET_PAY_TYPE = '@@SET_PAY_TYPE';

const initialState = {};

function initStore() {
    return {
        type: INIT_STORE
    }
}

function applyInitStore() {
    return {}
}

function setStore(info) {
    return {
        type: SET_STORE,
        info
    }
}

function applySetStore(state, action) {
    const { info } = action;
    return {
        ...state,
        info
    }
}

function setStoreCd(storeCd) {
    return {
        type: SET_STORE_CD,
        storeCd
    }
}

function applySetStoreCd(state, action) {
    const { storeCd } = action;
    return {
        ...state,
        storeCd
    }
}

function setPayType(payType) {
    return {
        type: SET_PAY_TYPE,
        payType
    }
}

function applySetPayType(state, action) {
    const { payType } = action;
    return {
        ...state,
        payType
    }
}

function reducer(state = initialState, action) {
    switch(action.type) {
        default:
            return state;
        case INIT_STORE:
            return applyInitStore();
        case SET_STORE:
            return applySetStore(state, action);
        case SET_STORE_CD:
            return applySetStoreCd(state, action);
        case SET_PAY_TYPE:
            return applySetPayType(state, action);
    }
}

const actionCreator = {
    initStore,
    setStore,
    setStoreCd,
    setPayType
};

export {actionCreator as storeActions};

export default reducer;