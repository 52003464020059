import React, {Suspense, useEffect} from "react";
import './App.css';
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./redux/store";

const WaitScreen = React.lazy(() => import('./pages/Wait'))
const MainScreen = React.lazy(() => import('./pages/main/Main'))

const loading = (
    <div className={"pt-3 text-center"}>
        <div className={"sk-spinner sk-spinner-pulse"}/>
    </div>
)

function App() {
    return (
        <Provider store={store}>
            <HashRouter>
                <Suspense fallback={loading}>
                    <Routes>
                        <Route path={"/"} name={"Intro"} element={<WaitScreen/>}/>
                        <Route exact path={"/main"} name={"Main"} element={<MainScreen/>}/>
                    </Routes>
                </Suspense>
            </HashRouter>
        </Provider>
    )
}

export default App;
