const initialState = {
    list: [],
    orderNum: 0
}

const SET_ORDER_NUMBER = '@order/SET_ORDER_NUMBER';

function setOrderNumber(orderNum) {
    return {
        type: SET_ORDER_NUMBER,
        orderNum
    }
}

function applySetOrderNumber(state, action) {
    const {orderNum} = action;
    return {
        ...state,
        orderNum
    }
}

function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
        case SET_ORDER_NUMBER:
            return applySetOrderNumber(state, action);
    }
}

const actionCreators = {
    setOrderNumber,
}

export {actionCreators as orderActions};

export default reducer;