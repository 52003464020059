const INIT_PRINTER_PORT = "@@INIT_PRINTER_PORT";
const SET_PRINTER_PORT = "@@SET_PRINTER_PORT";

const initialState = {};

function initPrinterPort() {
	return {
		type: INIT_PRINTER_PORT
	};
}

function applyInitPrinterPort() {
	return null;
}

function setPrinterPort(printer) {
	return {
		type: SET_PRINTER_PORT,
		printer
	};
}

function applySetPrinterPort(state, action) {
	const { printer } = action;
	return {
		...state,
		printer
	};
}

function reducer(state = initialState, action) {
	switch (action.type) {
		default:
			return state;
		case INIT_PRINTER_PORT:
			return applyInitPrinterPort();
		case SET_PRINTER_PORT:
			return applySetPrinterPort(state, action);
	}
}

const actionCreator = {
	initPrinterPort,
	setPrinterPort
};

export { actionCreator as printerActions };

export default reducer;
